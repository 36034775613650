export enum COMMON_ROUTES {
  HOME = "/",
  POS = "/pos",
  PRIVACY_POLICY = "/privacy-policy",
  TERM_AND_CONDTION = "/terms-and-conditions",
  NEED_HELP = "/need-help",
  DISCLAIMER = "/disclaimer",
  REFUND_AND_CANCELLATION = "/refund-and-cancellation",
  ABOUT_US = "/about-us",
  CONTACT_US = "/contact-us",
  FREE_CLAIM_ASSISTANCE = "/free-claim-assistance",
  COMPLAINT = "/complaint",
  CAREER = "/career",
  CLAIMS = "/claim",
  FAQ = "/faq",
  SITEMAP = "/sitemap",

  PRODUCT_HEALTH = "/health-insurance",
  PRODUCT_CAR = "/car-insurance",
  PRODUCT_TW = "/two-wheeler-insurance",
  PRODUCT_TERM = "/term-insurance",
  CARRER = "CARRER",

  HEALTH_FORM = "/form/container/health/form",
  CAR_FORM = "/form/container/car/form",
  TW_FORM = "/form/container/tw/form",
  HOME_FORM = "/form/container/home/form",
  TERM_FORM = "/form/container/term/form",
  TRAVEL_FORM = "/form/container/travel/form",
}
